<template>
  <div>
    <div
      :style="getElementStyle"
      v-show="!isFromDocument ? data.properties.filed_content != 'Hide' : true"
    >
      <div :style="isFromDocument ? getStyleInDocument : ''">
        <div
          :class="isFromDocument ? '' : ''"
          :style="isFromDocument ? getStyleInDocument : ''"
        >
          <el-row align="middle">
            <el-col
              :span="
                showLabel && !isDefalutPosq ? 4 : data.description ? 4 : 24
              "
              v-if="
                data &&
                data.properties &&
                !data.properties.hideLabel &&
                hasLabel
              "
              :style="computedStyles"
            >
              <label
                style="white-space: nowrap"
                for="text"
                :style="getLabelStyles"
                v-if="!fromRepeatable && !isFromDocument && !profilePage"
                >{{ label || data.label }}</label
              >
              <span
                v-if="data.validations.required"
                style="color: red; font-weight: bold"
              >
                *
              </span>

              <el-popover
                v-if="data.description && !isFromDocument"
                placement="top-start"
                trigger="hover"
                :content="data.description"
              >
                <i class="el-icon-info" slot="reference"></i>
              </el-popover>
            </el-col>
            <el-col
              v-else-if="!isFromDocument"
              :span="showLabel ? 4 : data.description ? 20 : 24"
              ref="colRef"
              :style="computedStyles"
            >
              <span
                v-if="hasLabel"
                style="
                  color: white;
                  display: inline-block;
                  height: 20px;
                  opacity: 0;
                "
              ></span>
            </el-col>
            <el-col :span="showLabel && !isDefalutPosq ? 12 : 24">
              <div
                v-if="!isView"
                :style="isFromDocument ? getStyleInDocument : ''"
              >
                <div
                  v-if="checkIsCurrency || data.validations.currency"
                  :style="isFromDocument ? getStyleInDocument : ''"
                >
                  <currency-input
                    v-if="data.validations.currency"
                    :locale="data.validations.locale"
                    :placeholder="data.key"
                    :currency="data.validations.currency"
                    v-model="form[data.key]"
                    class="el-input__inner"
                    :style="isFromDocument ? getStyleInDocument : ''"
                    :readonly="true"
                    :id="data.key"
                    :suffix-icon="
                      data.properties && data.properties.el_icon
                        ? data.properties.el_icon
                        : ''
                    "
                    :class="{
                      'disabled-field': checkIsDisabled,
                      'left-aligned-input': textAlignment === 'left',
                      'right-aligned-input': textAlignment === 'right',
                      'center-aligned-input': textAlignment === 'center',
                    }"
                  />
                  <currency-input
                    v-else
                    :placeholder="data.key"
                    :style="isFromDocument ? getStyleInDocument : ''"
                    :currency="checkIsCurrency.currency_type"
                    v-model="form[data.key]"
                    :readonly="true"
                    :class="isFromDocument ? 'custom-input' : 'el-input__inner'"
                    :id="data.key"
                    :suffix-icon="
                      data.properties && data.properties.el_icon
                        ? data.properties.el_icon
                        : ''
                    "
                  />

                  <!-- <span style="display: none" v-if="!isFromDocument">
                    {{ parseFloat(getValue).toFixed(this.data.decimals) }}
                  </span> -->
                </div>

                <div v-else :style="isFromDocument ? getStyleInDocument : ''">
                  <span v-if="data.currency">
                    <currency-input
                      :placeholder="data.key"
                      :currency="data.currency"
                      v-model="form[data.key]"
                      :readonly="true"
                      class="el-input__inner"
                      :style="isFromDocument ? getStyleInDocument : ''"
                      :id="data.key"
                      :suffix-icon="
                        data.properties && data.properties.el_icon
                          ? data.properties.el_icon
                          : ''
                      "
                      :class="{
                        'disabled-field': checkIsDisabled,
                        'left-aligned-input': textAlignment === 'left',
                        'right-aligned-input': textAlignment === 'right',
                        'center-aligned-input': textAlignment === 'center',
                      }"
                  /></span>
                  <input
                    v-if="checkIsTime && !isDate(form[data.key])"
                    :placeholder="data.key"
                    :value="getTimeFormatedValue(form[data.key])"
                    :readonly="true"
                    :style="{
                      'text-align': inputTextAlignment,
                      ...(isFromDocument ? getStyleInDocument : getStyle),
                    }"
                    :suffix-icon="
                      data.properties && data.properties.el_icon
                        ? data.properties.el_icon
                        : ''
                    "
                    :id="data.key"
                    class="nfe"
                  />

                  <input
                    v-if="
                      !data.currency &&
                      !isFromDocument &&
                      !checkIsTime &&
                      !isDate(form[data.key])
                    "
                    :placeholder="data.key"
                    class="nfe"
                    v-model="form[data.key]"
                    :readonly="true"
                    :style="{
                      'text-align': inputTextAlignment,
                      ...(isFromDocument ? getStyleInDocument : getStyle),
                    }"
                    :id="data.key"
                    :suffix-icon="
                      data.properties && data.properties.el_icon
                        ? data.properties.el_icon
                        : ''
                    "
                    :class="{
                      'disabled-field': checkIsDisabled,
                      'left-aligned-input': textAlignment === 'left',
                      'right-aligned-input': textAlignment === 'right',
                      'center-aligned-input': textAlignment === 'center',
                    }"
                  />
                  <input
                    v-if="
                      !data.currency &&
                      isFromDocument &&
                      !checkIsTime &&
                      !isDate(form[data.key])
                    "
                    :placeholder="data.key"
                    :style="{
                      'text-align': inputTextAlignment,
                      ...getStyleInDocument,
                    }"
                    v-model="form[data.key]"
                    :readonly="true"
                    :suffix-icon="
                      data.properties && data.properties.el_icon
                        ? data.properties.el_icon
                        : ''
                    "
                    :id="data.key"
                    class="el-input"
                  />
                  <el-input
                    v-if="isTime(form[data.key])"
                    :placeholder="data.placeholder || data.label"
                    v-model="form[data.key]"
                    prefix-icon="el-icon-date"
                    :readonly="true"
                  >
                  </el-input>
                  <el-date-picker
                    v-if="isDate(form[data.key]) && !isTime(form[data.key])"
                    prefix-icon="el-icon-date"
                    v-model="form[data.key]"
                    :readonly="true"
                    :type="isDateTime() ? 'datetime' : 'date'"
                    :placeholder="data.placeholder || data.label"
                    :format="
                      isDateTime()
                        ? 'MM-dd-yyyy HH:mm:ss'
                        : getDefaultDateFormat &&
                          getDefaultDateFormat.includes('Do') == false
                        ? getDefaultDateFormat
                            .replace('YYYY', 'yyyy')
                            .replace('DD', 'dd')
                        : 'MM-dd-yyyy'
                    "
                  >
                  </el-date-picker>
                  <!-- <span style="display: none" v-if="!isFromDocument">{{
                    parseFloat(getValue).toFixed(this.data.decimals)
                  }}</span> -->
                </div>
              </div>
            </el-col>

            <div v-if="isView" :style="{ textAlign: inputTextAlignment() }">
              <template
                v-if="form[data.key] !== undefined && form[data.key] !== null"
              >
                <span v-if="data.validations.currency">
                  {{
                    Number(form[data.key])
                      | toCurrency(data.validations.currency)
                  }}
                </span>
                <span v-else-if="checkIsTime">
                  {{ form[data.key] | toTimeFormat(data.time_result_type) }}
                </span>
                <div v-else-if="isDate(form[data.key])">
                  {{ form[data.key] | globalDateFormat }}
                </div>
                <div v-else>
                  {{ form[data.key] }}
                </div>
              </template>
              <template v-else>-</template>
            </div>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import userPermissionsHelper from "@/mixins/userPermissionsHelper";
import * as mexp from "math-expression-evaluator";
import CurrencyInput from "./../../vue-weblineindia-currency-input/src/component";
import { mapGetters } from "vuex";
import * as moment from "moment";
import { bus } from "../../../main";
export default {
  mixins: [userPermissionsHelper],
  components: { CurrencyInput },
  props: [
    "dataTableField",
    "isInDataTable",
    "label",
    "data",
    "form",
    "templatesData",
    "workflowData",
    "fromRepeatable",
    "isView",
    "isFromDocument",
    "allFields",
    "company_id",
    "document_id",
    "isEntityVariable",
    "originalField",
    "entityDataId",
    "isDisable",
    "profilePage",
    "parentFormData",
    "fieldsData",
    "hasLabel",
    "checkIsDisabled",
    "fromEntityViews",
  ],
  data() {
    return {
      currency: "",
      validations: [],
      showLabel: true,
      isDefalutPos: true,
      fieldvalue: "",
      result_type: "NUMBER",
      previous_form: {},
      textAlignment: "left",
    };
  },
  computed: {
    ...mapGetters("templatesData", ["getTemplatesData"]),
    ...mapGetters("globalVariables", ["getAllGlobalVariables"]),
    ...mapGetters("auth", ["getDefaultDateFormat"]),
    getStyle() {
      return `height:${this.data.height - 30}px; width: ${
        this.data.width
      }px !important;`;
    },
    getTimeFormatedValue() {
      return (value) => {
        let format =
          this.data.time_result_type && this.data.time_result_type !== ""
            ? this.data.time_result_type
            : "Time";
        if (this.isEntityVariable) {
          let finalValue = "";
          switch (format) {
            case "Time":
              finalValue += value;
              break;
            case "HH":
              finalValue += value + " Hrs";
              break;
            case "MM":
              finalValue += value + " Mins";
              break;
            case "SS":
              finalValue += value + " Secs";
              break;
          }
          return finalValue;
        }
        return this.$options.filters.toTimeFormat(value, format);
      };
    },
    getStyleInDocument() {
      // height:${this.data.height - 30}px;
      return ` width:99.60% !important; font-size:${
        this.data.font && this.data.font.size ? this.data.font.size : "16"
      }px;font-style: ${
        (this.data && this.data.font && this.data.font.style == 2) ||
        (this.data && this.data.font && this.data.font.style == 3)
          ? "italic"
          : "normal"
      };font-weight:${
        (this.data && this.data.font && this.data.font.style == 1) ||
        (this.data && this.data.font && this.data.font.style == 3)
          ? "bold"
          : "normal"
      };color:${
        this.data && this.data.font && this.data.font.color
          ? this.data.font.color
          : "black"
      };font-family:${
        this.data && this.data.font && this.data.font.name
          ? this.data.font.name
          : "helvetica"
      }`;
    },
    isDefalutPosq() {
      if (this.data?.styles?.labelStyle == "right") {
        return false;
      } else {
        return true;
      }
    },
    checkIsCurrency() {
      if (this.data.selected_fields && this.data.selected_fields.length) {
        return this.data.selected_fields.find(
          (e) => e.input_type == "CURRENCY"
        );
      }
      return false;
    },
    checkIsTime() {
      if (this.data.selected_fields && this.data.selected_fields.length) {
        return this.data.selected_fields.find((e) => e.input_type == "TIME");
      }
      return false;
    },
    is_date() {
      return (input) => {
        if (Object.prototype.toString.call(input) === "[object Date]")
          return true;
        return false;
      };
    },

    // getValue() {
    //   if (this.isFromDocument) {
    //     return "";
    //   }
    //   if (
    //     this.isEntityVariable &&
    //     this.originalField.input_type === "ENTITY_VARIABLE"
    //   ) {
    //     return;
    //   }

    //   let fields = this.data.selected_fields;
    //   let data = {},
    //     parentData = {};
    //   if (Object.keys(this.form).length) {
    //     Object.keys(this.form).forEach((key) => {
    //       if (key.indexOf("#") > -1) {
    //         data[key.split("#")[1]] = this.form[key];
    //       } else {
    //         if (this.data.selected_fields[0].input_type === "CURRENCY") {
    //           data[key] = this.form[key];
    //         } else if (this.data.selected_fields) {
    //           data[key] = this.form[key];
    //         } else {
    //           data[key] = parseFloat(this.form[key]).toFixed(
    //             this.data.decimals
    //           );
    //         }
    //       }
    //     });
    //   }
    //   fields.forEach((fl) => {
    //     if (fl.template_id) {
    //       data[fl.key] = this.getTemplateValue(fl.template_id, fl.key);
    //     }
    //   });
    //   let newFields = fields.map((fl) => {
    //     if (
    //       !fl?.data_table_key &&
    //       fl.template_name &&
    //       fl.key &&
    //       this.parentFormData
    //     ) {
    //       if (!fl.key.includes("parentData")) {
    //         parentData["parentData" + fl.key] = this.parentFormData[fl.key];
    //         fl.key = "parentData" + fl.key;
    //       } else {
    //         parentData[fl.key] =
    //           this.parentFormData[fl.key.split("parentData")[1]];
    //       }
    //     }
    //     return fl;
    //   });
    //   return this.setValueBasedOnFields(newFields, {
    //     ...data,
    //     ...parentData,
    //   });
    // },
    computedStyles() {
      const styles = {};

      // Set display to flex
      styles.display = "flex";

      // Set justify-content based on data.styles.labelAlignments
      if (this.data?.styles?.labelAlignments) {
        styles.justifyContent = this.data.styles.labelAlignments;
      }

      // Add other dynamic styles if needed

      return styles;
    },
  },
  created() {
    bus.$on("formula-updated", () => {
      this.getValueForDocument();
    });
  },
  mounted() {
    if (this.data.styles && this.data.styles.labelStyle) {
      if (this.data.styles && this.data.styles.labelStyle == "hide") {
        this.showLabel = false;
      }
      // if (this.data.styles.labelStyle == "right") {
      //   this.isDefalutPos = false;
      // }
    }
    //if (this.data.default_value) {
    //  this.form[this.data.key] = this.data.default_value;
    //}

    if (this.data.validations.locale === "nl-NL") {
      this.data.validations.locale = "en-IE";
    }
    if (this.data.validations.currency) {
      this.form[this.data.key + "_currency"] = this.data.validations.currency;
    } else {
      this.form[this.data.key + "_currency"] =
        this.checkIsCurrency?.currency_type;
    }
    // Getting template data

    if (this.entityDataId) {
      // this.getTemplateIdsData();
    }
    // this.getGloablVariables();

    if (this.isFromDocument) {
      this.getValueForDocument();
    }
    this.result_type = this.getResultType(this.data.selected_fields);
    bus.$on("global-date", () => {
      this.getValueGlobal();
    });
    this.getValue();
  },
  watch: {
    parentFormData: {
      async handler(data) {
        let current_form = {};
        this.data.selected_fields.forEach((fl) => {
          if (fl.type == "FIELD" || fl.type == "FORMULA") {
            if (this.parentFormData && this.parentFormData[fl.key]) {
              this.$set(current_form, fl.key, this.parentFormData[fl.key]);
            } else {
              this.$set(current_form, fl.key, data[fl.key]);
            }
          }
        });
        if (
          JSON.stringify(current_form) !== JSON.stringify(this.previous_form)
        ) {
          this.previous_form = {};
          this.data.selected_fields.forEach((fl) => {
            if (fl.type == "FIELD" || fl.type == "FORMULA") {
              if (this.parentFormData && this.parentFormData[fl.key]) {
                this.$set(
                  this.previous_form,
                  fl.key,
                  this.parentFormData[fl.key]
                );
              } else {
                this.$set(this.previous_form, fl.key, data[fl.key]);
              }
            }
          });
          this.getValue();
        }
      },
    },
    form: {
      async handler(data) {
        let current_form = {};
        this.data.selected_fields.forEach((fl) => {
          if (fl.type == "FIELD" || fl.type == "FORMULA") {
            if (this.parentFormData && this.parentFormData[fl.key]) {
              this.$set(current_form, fl.key, this.parentFormData[fl.key]);
            } else {
              this.$set(current_form, fl.key, data[fl.key]);
            }
          }
        });
        if (
          JSON.stringify(current_form) !== JSON.stringify(this.previous_form)
        ) {
          this.previous_form = {};
          this.data.selected_fields.forEach((fl) => {
            if (fl.type == "FIELD" || fl.type == "FORMULA") {
              if (this.parentFormData && this.parentFormData[fl.key]) {
                this.$set(
                  this.previous_form,
                  fl.key,
                  this.parentFormData[fl.key]
                );
              } else {
                this.$set(this.previous_form, fl.key, data[fl.key]);
              }
            }
          });
          this.getValue();
        }
        if (data[this.data.key] !== this.fieldvalue && !this.isInDataTable) {
          this.fieldvalue = data[this.data.key];
          this.applyFormRules();
        }
      },
      deep: true,
    },
    "data.styles.inputTextAlignments": {
      handler(newVal) {
        // Whenever inputTextAlignments changes, update the computed property.
        this.textAlignment = newVal;
      },
      immediate: true, // This will trigger the handler immediately when the component is created
    },
  },
  methods: {
    isDateTime() {
      let dateTime = (this.data?.selected_fields || []).filter(
        (e) => e.input_type == "DATE_TIME"
      );
      return dateTime && dateTime.length === 1 ? true : false;
    },
    copyDocument() {
      this.$emit("copy-document", this.data.key);
    },
    getValue() {
      if (this.isFromDocument) {
        return "";
      }
      if (
        this.isEntityVariable &&
        this.originalField.input_type === "ENTITY_VARIABLE"
      ) {
        return;
      }

      let fields = this.data.selected_fields;

      let data = {},
        parentData = {};
      if (Object.keys(this.form).length) {
        Object.keys(this.form).forEach((key) => {
          if (key.indexOf("#") > -1) {
            data[key.split("#")[1]] = this.form[key];
          } else {
            if (this.data.selected_fields[0].input_type === "CURRENCY") {
              data[key] = this.form[key];
            } else if (this.data.selected_fields[0].input_type === "TIME") {
              data[key] = this.form[key];
            } else if (this.data.selected_fields) {
              data[key] = this.form[key];
            } else {
              data[key] = parseFloat(this.form[key]).toFixed(
                this.data.decimals
              );
            }
          }
        });
      }
      fields.forEach((fl) => {
        if (fl.template_id) {
          data[fl.key] = this.getTemplateValue(fl.template_id, fl.key);
        }
      });
      let newFields = fields.map((fl) => {
        if (
          !fl?.data_table_key &&
          fl.template_name &&
          fl.key &&
          this.parentFormData
        ) {
          if (!fl.key.includes("parentData")) {
            parentData["parentData" + fl.key] = this.parentFormData[fl.key];
            fl.key = "parentData" + fl.key;
          } else {
            parentData[fl.key] =
              this.parentFormData[fl.key.split("parentData")[1]];
          }
        }
        return fl;
      });
      return this.setValueBasedOnFields(newFields, {
        ...data,
        ...parentData,
      });
    },
    inputTextAlignment() {
      return this.data.result_type == "DATE" ? "left" : this.textAlignment; // Set a default value if not provided
    },
    getResultType(fields = []) {
      let type = "NUMBER";
      if (fields.length) {
        let timeFields = fields.filter(
          (e) =>
            e.input_type == "TIME" ||
            (e.type == "FORMULA" && e.result_type == "TIME") ||
            (e.type == "GLOBAL_VARIABLE" && e.inputType == "TIME")
        );
        let dateFields = fields.filter(
          (e) =>
            e.input_type == "DATE" ||
            (e.type == "FORMULA" && e.result_type == "DATE") ||
            (e.type == "GLOBAL_VARIABLE" && e.inputType == "DATE")
        );
        // let listFields = fields.filter((e) => e.input_type == "LIST");
        // let numberFields = fields.filter(
        //   (e) =>
        //     e.type == "NUMBER" ||
        //     (e.type == "FORMULA" && e.result_type == "NUMBER") ||
        //     e.input_type == "NUMBER" ||
        //     (e.type == "GLOBAL_VARIABLE" && e.inputType == "NUMBER")
        // );
        let currencyField = fields.filter(
          (e) =>
            e.input_type == "CURRENCY" ||
            (e.type == "FORMULA" && e.result_type == "CURRENCY") ||
            (e.type == "GLOBAL_VARIABLE" && e.inputType == "CURRENCY")
        );
        if (currencyField && currencyField.length) {
          type = "CURRENCY";
        } else if (dateFields && dateFields.length == 1) {
          type = "DATE";
        } else if (timeFields && timeFields.length == 1) {
          type = "TIME";
        }
      }
      return type;
    },
    checkDate(string) {
      var dateRegex = /^\d{4}-\d{2}-\d{2}$/;
      var dateRegex1 = /^\d{2}-\d{2}-\d{4}$/;
      var timeRegex = /^\d{2}:\d{2}:\d{2}$/;
      let dateStamp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
      if (timeRegex.test(string)) {
        // return moment("2000-01-01 " + string, "YYYY-MM-DD HH:mm:ss");
      } else {
        if (
          dateRegex.test(string) ||
          dateRegex1.test(string) ||
          dateStamp.test(string)
        ) {
          return string;
        } else {
          // return moment().utc().format();
        }
      }
    },
    convertSecondsToHoursMinutes(seconds, outputType) {
      if (outputType === "hours" || outputType === "hh") {
        const hours = Math.floor(seconds / 3600);

        return hours;
      } else if (outputType === "minutes" || outputType === "mm") {
        const minutes = Math.floor(seconds / 60);

        return minutes;
      } else {
        return seconds;
      }
    },

    convertHoursMinutesToSeconds(value, inputType) {
      if (inputType === "hours" || inputType === "hh") {
        const seconds = value * 3600;

        return seconds;
      } else if (inputType === "minutes" || inputType === "mm") {
        const seconds = value * 60;

        return seconds;
      } else {
        return value;
      }
    },
    applyFormRules() {
      this.$emit("applyFormRules");
    },
    getValueGlobal() {
      if (this.isFromDocument) {
        return "";
      }
      let fields = this.data.selected_fields;

      let data = {};
      if (Object.keys(this.form).length) {
        Object.keys(this.form).forEach((key) => {
          if (key.indexOf("#") > -1) {
            data[key.split("#")[1]] = this.form[key];
          } else {
            if (this.data.selected_fields[0].input_type === "CURRENCY") {
              data[key] = this.form[key];
            } else if (this.data.selected_fields[0].input_type === "TIME") {
              data[key] = this.form[key];
            } else if (this.data.selected_fields) {
              data[key] = this.form[key];
            } else {
              data[key] = parseFloat(this.form[key]).toFixed(
                this.data.decimals
              );
            }
          }
        });
      }

      return this.setValueBasedOnFields(fields, data);

      // }
    },

    getValueForDocument() {
      let fields = this.data.selected_fields;

      let data = {};
      if (this.allFields && this.allFields.length) {
        this.allFields.forEach((field) => {
          data[field.key] = field.value;
        });
        data = { ...data, ...this.form };
      }
      return this.setValueBasedOnFields(fields, data);
    },
    checkReadonly() {
      if (this.checkIsDisabled) {
        return true;
      }
      //If it's an entity varaible, have to check access

      if (
        this.data.field_assignable === "read_only" &&
        this.data.is_entity_variable
      ) {
        return true;
      }
      return !this.fieldFilledByCurrentUser(this.data.filled_by);
    },
    async getTemplateIdsData() {
      let templateIds = [];
      this.data.selected_fields.forEach((element) => {
        if (element.template_id) {
          if (templateIds.indexOf(element.template_id) < 0) {
            templateIds.push(element.template_id);
          }
        }
      });
      let params = {
        template_ids: templateIds,
        entity_data_id: this.entityDataId,
        status: "ACTIVE",
      };
      await this.$store.dispatch("templatesData/fetchUserTemplateData", params);
    },
    getTemplateValue(templateId, key) {
      let value = 0;
      if (this.getTemplatesData && this.getTemplatesData.length) {
        this.getTemplatesData.forEach((item) => {
          if (item.template_id == templateId) {
            value =
              item.template_data && item.template_data[key]
                ? item.template_data[key]
                : "";
          }
        });
        return value ? value : "";
      }
    },
    async getGloablVariables() {
      let params = {
        get_all: true,
      };
      if (this.company_id && this.document_id) {
        params["company_id"] = this.company_id;
        params["document_id"] = this.document_id;
      }
      await this.$store.dispatch(
        "globalVariables/fetchGlobalVariables",
        params
      );
    },
    getGlobalVariableValue(key) {
      let value = 0;
      if (
        this.getAllGlobalVariables &&
        this.getAllGlobalVariables.data &&
        this.getAllGlobalVariables.data.length
      ) {
        this.getAllGlobalVariables.data.forEach((element) => {
          if (element.label == key) {
            value = element.value;
            if (element.currency) {
              this.data.currency = element.currency;
            }
            if (element.input_type === "DATE") {
              if (element.datestamp) {
                value = moment().startOf("day");
              }
            }
          }
        });
        return value;
      } else {
        return "";
      }
    },

    isUniqueTimestampFormat(value) {
      const timestampRegex = /^[0-9]{13}$/; // 13 digits for Unix timestamp in milliseconds
      return timestampRegex.test(value);
    },
    evaluateDateExpression(expression, incrementType) {
      try {
        if (expression.includes("NaN") || expression.includes(undefined)) {
          return [];
        }
        const parts = expression.split("+");

        const results = [];
        let accumulateNumber = 0;

        for (const part of parts) {
          if (this.isUniqueTimestampFormat(part)) {
            const timestamp = parseInt(part);
            const currentDate = new Date(timestamp);
            let newDate = new Date(currentDate);

            switch (incrementType) {
              case "YYYY":
                newDate.setFullYear(
                  currentDate.getFullYear() + accumulateNumber
                );
                break;
              case "MM":
                newDate.setMonth(currentDate.getMonth() + accumulateNumber);
                break;
              default:
                newDate.setDate(currentDate.getDate() + accumulateNumber);
                break;
            }

            results.push(
              newDate.toDateString() +
                " " +
                newDate.toTimeString().slice(0, 8) +
                " GMT"
            );
            accumulateNumber = 0;
          } else if (
            part.includes("*") ||
            part.includes("+") ||
            part.includes("-")
          ) {
            accumulateNumber += mexp.eval(part);
          } else {
            results.push(accumulateNumber + mexp.eval(part));
            accumulateNumber = 0;
          }
        }

        return results;
      } catch (e) {
        // console.log("evaluateDateExpression", e);
      }
    },
    getTimeValue(timeString, type) {
      if (timeString && timeString.includes(":")) {
        const [hours, minutes, seconds] = timeString.split(":").map(Number);

        switch (type) {
          case "hours":
          case "hh":
            return hours;
          case "minutes":
          case "mm":
            return minutes;
          case "seconds":
            return seconds;
          default:
            return 0;
        }
      }
      return 0;
    },
    getFieldValue(field, data) {
      if (field?.key && this.data && data[field.key]) {
        if (field.input_type == "CHECKBOX") {
          return 1;
        }
        return data[field.key];
      }
      return 0;
    },
    dateDifference(startDate, endDate, resultType = "DD") {
      const startMoment = moment(startDate);
      const endMoment = moment(endDate);
      switch (resultType.toUpperCase()) {
        case "DD":
          return endMoment.diff(startMoment, "days");
        case "MM":
          return endMoment.diff(startMoment, "months");
        case "YYYY":
          return endMoment.diff(startMoment, "years");
        case "HH":
          return endMoment.diff(startMoment, "hours");
        default:
          return endMoment.diff(startMoment, "days");
      }
    },
    timeDifference(startTime, endTime, resultType = "HH") {
      const startMoment = moment(startTime);
      const endMoment = moment(endTime);

      switch (resultType.toUpperCase()) {
        case "HH":
          return endMoment.diff(startMoment, "hours", true);
        case "MM":
          return endMoment.diff(startMoment, "minutes", true);
        case "SS":
          return endMoment.diff(startMoment, "seconds", true);
        default:
          return 0;
      }
    },
    isDate(string) {
      var dateRegex = /^\d{4}-\d{2}-\d{2}$/;
      var dateRegex1 = /^\d{2}-\d{2}-\d{4}$/;
      var timeRegex = /^\d{2}:\d{2}:\d{2}$/;
      let dateStamp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;
      if (
        dateRegex.test(string) ||
        dateRegex1.test(string) ||
        dateStamp.test(string) ||
        timeRegex.test(string)
      ) {
        return true;
      }
      return false;
    },
    isTime(string) {
      var timeRegex = /^\d{2}:\d{2}:\d{2}$/;
      if (timeRegex.test(string)) {
        return true;
      }
      return false;
    },
    getMonthFromDate(dateString) {
      const date = new Date(dateString);
      return date.getMonth();
    },
    getDayFromDate(dateString) {
      const date = new Date(dateString);
      return date.getDate();
    },
    getHoursFromTime(timeString) {
      if (timeString && timeString.includes(":")) {
        const timeParts = timeString.split(":");
        const min = parseInt(timeParts[1], 10);
        return min;
      }
      return 0;
    },

    convertToDate(value, resultType = "DD") {
      let currentDate = moment("01-01-0001");
      switch (resultType) {
        case "MM":
          currentDate = moment("01-01-0001").add(value, "months");
          break;
        case "DD":
          currentDate = moment("01-01-0001").add(value, "days");
          break;
        case "YYYY":
          currentDate = moment("01-01-0001").add(value, "years");
          break;
        default:
          currentDate = moment("01-01-0001").add(value, "days");
      }
      return currentDate;

      // const currentDate = new Date("01-01-0001");
      // switch (resultType) {
      //   case 'MM':
      //     currentDate.setMonth(currentDate.getMonth() + value);
      //     break;
      //   case 'DD':
      //     currentDate.setDate(currentDate.getDate() + value);
      //     break;
      //   case 'YYYY':
      //     currentDate.setFullYear(currentDate.getFullYear() + value);
      //     break;
      //   default:
      //     throw new Error('Invalid result type. Please provide "months", "days", or "years".');
      // }
      // return currentDate;
    },
    convertToTime(value, resultType) {
      let currentTime = moment("00:00:00", "HH:mm:ss");

      switch (resultType) {
        case "HH":
          currentTime = moment("00:00:00", "HH:mm:ss").add(value, "hours");
          break;
        case "mm":
          currentTime = moment("00:00:00", "HH:mm:ss").add(value, "minutes");
          break;
        case "ss":
          currentTime = moment("00:00:00", "HH:mm:ss").add(value, "seconds");
          break;
        default:
          throw new Error(
            'Invalid result type. Please provide "HH", "mm", or "ss".'
          );
      }
      return currentTime;
    },
    setValueBasedOnFields(fields, data) {
      let expr = "",
        dateCount = 0,
        offsetHours = 0,
        timeCount = 0,
        offsetDays = 0,
        offsetMonths = 0,
        dateValuesCount = 0,
        timeFields = [];
      fields.forEach((e) => {
        if (
          e?.type == "FIELD" ||
          e?.type == "FORMULA" ||
          e?.type == "GLOBAL_VARIABLE"
        ) {
          if (e?.input_type == "DATE_RANGE") {
            let value = this.getFieldValue(e, data);
            let value1 = this.dateDifference(
              "01-01-0001",
              moment(value[0]).format("MM-DD-YYYY"),
              this.data.date_result_type
            );
            let value2 = this.dateDifference(
              "01-01-0001",
              moment(value[1]).format("MM-DD-YYYY"),
              this.data.date_result_type
            );
            expr = expr + (value2 - value1);
          } else {
            let value =
              e.type == "GLOBAL_VARIABLE"
                ? this.getGlobalVariableValue(e.field_name)
                : this.getFieldValue(e, data);
            if (
              (this.isDate(value) ||
                e.input_type == "DATE" ||
                e.input_type == "DATE_TIME") &&
              e.input_type != "TIME" &&
              e.inputType != "TIME"
            ) {
              if (value) {
                dateValuesCount++;
              }
              if (this.data.date_result_type != "DD") {
                offsetDays += this.getDayFromDate(value);
              }
              if (this.data.date_result_type == "YYYY") {
                offsetMonths += this.getMonthFromDate(value);
              }
              if (e.input_type == "DATE_TIME") {
                offsetHours += new Date(value).getHours();
              }
              dateCount++;
              value = this.dateDifference(
                "01-01-0001",
                value,
                this.data.date_result_type
              );
            }
            if (this.isTime(value) || e.input_type == "TIME") {
              timeFields.push(value);
              if (this.data.time_result_type != "HH") {
                offsetHours += this.getHoursFromTime(value);
              }
              timeCount++;
              value = this.timeDifference(
                moment("00:00:00", "HH:mm:ss"),
                moment(value, "HH:mm:ss"),
                this.data.time_result_type
              );
            }
            expr = expr + value;
          }
        } else if (e?.type == "OPERATOR") {
          expr = expr + e.operator;
        } else if (e?.type == "NUMBER") {
          expr = expr + e.number;
        } else if (e?.type == "PARENTHESES") {
          expr = expr + e.parentheses;
        }
      });
      try {
        let calculatedValue = mexp.eval(expr);
        let finalValue = calculatedValue;
        if (Number(finalValue) && this.data.decimals) {
          finalValue = parseFloat(finalValue).toFixed(this.data.decimals || 0);
        } else if (this.data.date_result_type && dateCount == 2) {
          if (dateValuesCount == dateCount) {
            finalValue = parseFloat(finalValue).toString();
          } else {
            finalValue = 0;
          }
        } else if (this.data.time_result_type && timeCount == 2) {
          if (dateValuesCount == dateCount) {
            finalValue = Math.abs(parseFloat(finalValue).toString());
          } else {
            finalValue = 0;
          }
        } else if (dateCount == 1) {
          finalValue = this.convertToDate(
            finalValue,
            this.data.date_result_type
          );
          if (offsetDays > 1) {
            finalValue = moment(finalValue).add(offsetDays - 1, "days");
          }
          if (this.data.date_result_type == "YYYY" && offsetMonths > 0) {
            finalValue = moment(finalValue).add(offsetMonths, "month");
          }
          if (timeCount == 1 && timeFields.length == 1) {
            finalValue = this.addTimeToDate(finalValue, timeFields[0]);
          }
          if (offsetHours > 1) {
            finalValue = moment(finalValue).add(offsetHours, "hours");
            finalValue = moment(finalValue, "MM-dd-yyyy HH:mm:ss")
              .utc()
              .toISOString();
          } else {
            finalValue = moment(
              finalValue,
              this.getDefaultDateFormat
                ? this.getDefaultDateFormat
                : "MM-DD-YYYY"
            )
              .startOf("day")
              .utc()
              .toISOString();
          }
          if (!dateValuesCount) {
            finalValue = "";
          }
        } else if (this.data.time_result_type && timeCount == 1) {
          finalValue = this.convertToTime(
            finalValue,
            this.data.time_result_type
          );
          if (offsetHours > 1) {
            finalValue = moment(finalValue).add(offsetHours - 1, "hours");
          }
          finalValue = moment(finalValue).format("HH:mm:ss");
        }
        this.$set(this.form, this.data.key, finalValue);
      } catch (err) {
        if (this.form[this.data.key] != 0 && this.form[this.data.key] != "0") {
          this.$set(this.form, this.data.key, 0);
        }
      }
    },
    addTimeToDate(date, timeToAdd) {
      let [hours, minutes, seconds] =
        timeToAdd && timeToAdd.includes(":")
          ? timeToAdd.split(":").map(Number)
          : [0, 0, 0];
      let newDate = new Date(date);
      newDate.setHours(newDate.getHours() + hours);
      newDate.setMinutes(newDate.getMinutes() + minutes);
      newDate.setSeconds(newDate.getSeconds() + (seconds || 0));
      return newDate;
    },
    // setValueBasedOnFields(fields, data) {
    //   let gotValue = false;
    //   let finalValue = 0;
    //   let exp = "";

    //   let selectedFields = this.lodash.filter(fields, function (element) {

    //     if (element.input_type == "FORMULA") {
    //       return element.input_type == "FORMULA";
    //     }

    //     if (element.input_type == "DATE") {
    //       return element.input_type == "DATE";
    //     }
    //     if (element.input_type == "GLOBAL_VARIABLE") {
    //       return element.type == "GLOBAL_VARIABLE";
    //     }
    //     if (element.input_type == "NUMBER") {
    //       return element.input_type == "NUMBER";
    //     }
    //     if (element.type == "FORMULA") {
    //       return element.type == "FORMULA";
    //     }
    //     if (element.input_type == "SELECT") {
    //       return element.input_type == "SELECT";
    //     }
    //     if (element.input_type == "CURRENCY") {
    //       return element.input_type == "CURRENCY";
    //     }
    //     if (element.input_type == "TIME") {
    //       return element.input_type == "TIME";
    //     }
    //     if (element.input_type == "LIST") {
    //       return element.input_type == "LIST";
    //     }
    //     if (element.input_type == "DATE_TIME_RANGE") {
    //       return element.input_type == "DATE_TIME_RANGE";
    //     }
    //     if (element.input_type == "ENTITY_VARIABLE") {
    //       return element.input_type == "ENTITY_VARIABLE";
    //     }
    //     if (element.input_type == "DATE_TIME") {
    //       return element.input_type == "DATE_TIME";
    //     }
    //   });
    //   if (selectedFields && selectedFields.length) {
    //     const array = [];
    //     fields.forEach((element) => {
    //       if (element.key && element.key[0] == "#") {
    //         element.key = element.key.substring(1, element.key.length);
    //       }
    //       if (element.type == "OPERATOR") {
    //         array.push({
    //           operator: element.operator,
    //         });
    //       } else if (element.input_type == "NUMBER") {
    //         array.push({
    //           number: data[element.key] ? data[element.key] : "0",
    //         });
    //       } else if (element.type == "NUMBER") {
    //         array.push({
    //           number: element.number ? element.number : "0",
    //         });
    //       } else if (element.input_type == "DATE" ||element.input_type == "DATE_TIME") {
    //         array.push({
    //           date: data[element.key],
    //         });
    //       } else if (element.input_type == "SELECT") {
    //         array.push({
    //           select: data[element.key] ? data[element.key] : "0",
    //         });
    //       } else if (element.input_type == "TIME") {
    //         array.push({
    //           time: data[element.key] || "00:00:00",
    //         });
    //       } else if (element.input_type == "DATE_TIME_RANGE") {
    //         array.push({
    //           date_time_range: data[element.key],
    //         });
    //       } else if (element.input_type == "LIST") {
    //         array.push({
    //           list: data[element.key] ? data[element.key] : "0",
    //         });
    //       } else if (element.input_type == "FORMULA") {
    //         let pattern =
    //           /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
    //         let input = data[element.key];
    //         if (pattern.test(input) || this.checkDate(input)) {
    //           array.push({
    //             date: data[element.key] ? data[element.key] : "0",
    //           });
    //         } else {
    //           array.push({
    //             number: data[element.key] ? data[element.key] : "0",
    //           });
    //         }
    //       } else if (element.type == "FORMULA") {
    //         let x = "";
    //         if (element.template_id) {
    //           x = this.getTemplateValue(element.template_id, element.key);
    //         } else {
    //           x = data[element.key] ? data[element.key] + "" : x ? x : "0";
    //         }
    //         if (isNaN(x)) {
    //           if (this.is_date(new Date(x))) {
    //             array.push({
    //               date: x ? x : "0",
    //             });
    //           } else {
    //             array.push({
    //               number: "0",
    //             });
    //           }
    //         } else {
    //           array.push({
    //             number: x ? x : "0",
    //           });
    //         }
    //       } else if (element.type == "GLOBAL_VARIABLE") {
    //         this.getGlobalVariableValue(element.field_name);

    //         if (this.data.globaldate) {
    //           let value = new Date(this.data.globaldate);
    //           array.push({
    //             date: value,
    //           });
    //         }
    //         if (this.data.globalnumber) {
    //           let value = this.data.globalnumber;
    //           array.push({
    //             number: value ? value : "0",
    //           });
    //         }
    //       } else if (element.input_type === "CURRENCY") {
    //         let pattern = /^[0-9a-fA-F]{24}$/;
    //         let input = data[element.key];
    //         if (pattern.test(input)) {
    //           array.push({
    //             number: data[element.key + "/name"]
    //               ? data[element.key + "/name"]
    //               : "0",
    //           });
    //         } else {
    //           array.push({
    //             number: data[element.key] ? data[element.key] : "0",
    //           });
    //         }
    //       } else if (element.input_type === "ENTITY_VARIABLE") {
    //         var a = moment(data[element.key]);
    //         if (a.isValid) {
    //           array.push({
    //             date: data[element.key] ? data[element.key] : "0",
    //           });
    //         }
    //       } else if (element.input_type == "AGGREGATE_FUNCTION") {
    //         array.push({
    //           number: element.number ? element.number : "0",
    //         });
    //       }
    //     //   else if(element.input_type === "PAYMENT_VARIABLE")
    //     //   {

    //     //     if(element.inputType === "NUMBER"){
    //     //     array.push({
    //     //       number: data[element.key] ? data[element.key] : "0",
    //     //     });

    //     //   }
    //     //   else if(element.inputType === "DATE" || element.inputType ==="DATE_TIME"){
    //     //     array.push({
    //     //       date: data[element.key],
    //     //     });
    //     //   }
    //     //   else if (element.inputType === "CURRENCY") {
    //     //       array.push({
    //     //         number: data[element.key] ? data[element.key] : "0",
    //     //       });
    //     //   }
    //     // }
    //     });

    //     if (array && array.length) {
    //       let selectCount = this.lodash.filter(array, function (element) {
    //         return element.select != undefined || element.select != null;
    //       });

    //       // let formulaCount = this.lodash.filter(array, function (element) {
    //       //   return element.formula;
    //       // });

    //       let dateCount = this.lodash.filter(array, function (element) {
    //         return element.date;
    //       });

    //       let listCount = this.lodash.filter(array, function (element) {
    //         return element.list != undefined || element.list != null;
    //       });

    //       let timeCount = this.lodash.filter(array, function (element) {
    //         return element.time;
    //       });

    //       let date_time_range_Count = this.lodash.filter(
    //         array,
    //         function (element) {
    //           return element.date_time_range;
    //         }
    //       );

    //       let numberCount = this.lodash.filter(array, function (element) {
    //         return element.number;
    //       });

    //       let operatorCount = this.lodash.filter(array, function (element) {
    //         return element.operator;
    //       });
    //       if (date_time_range_Count && date_time_range_Count.length === 1) {
    //         let date_time_rangeArrayOne = moment(
    //           date_time_range_Count[0].date_time_range[0]
    //         );
    //         let date_time_rangeArrayTwo = moment(
    //           date_time_range_Count[0].date_time_range[1]
    //         );
    //         gotValue = true;
    //         if (this.data.date_result_type === "MM") {
    //           let countss = date_time_rangeArrayTwo.diff(
    //             date_time_rangeArrayOne,
    //             "months"
    //           );
    //           if (countss > 0) {
    //             finalValue = countss;
    //           } else {
    //             const value = countss * -1;
    //             finalValue = value;
    //           }
    //         } else if (this.data.date_result_type === "YYYY") {
    //           let countss = date_time_rangeArrayTwo.diff(
    //             date_time_rangeArrayOne,
    //             "years"
    //           );
    //           if (countss > 0) {
    //             finalValue = countss;
    //           } else {
    //             const value = countss * -1;
    //             finalValue = value;
    //           }
    //         } else {
    //           let countss = date_time_rangeArrayTwo.diff(
    //             date_time_rangeArrayOne,
    //             "days"
    //           );
    //           if (countss > 0) {
    //             finalValue = countss;
    //           } else {
    //             const value = countss * -1;
    //             finalValue = value;
    //           }
    //         }
    //       }

    //       if (
    //         timeCount &&
    //         timeCount.length == 1 &&
    //         operatorCount &&
    //         operatorCount.length == 1 &&
    //         numberCount &&
    //         numberCount.length == 1
    //       ) {
    //         let mappedTime = moment(timeCount[0].time, "HH:mm:ss");
    //         let operator = operatorCount[0]?.operator;
    //         let type = "hours";
    //         if (this.data.time_result_type) {
    //           type = this.data.time_result_type.toLowerCase();
    //         }
    //         if (operator == "+") {
    //           finalValue = mappedTime
    //             .add(numberCount[0]?.number, type)
    //             .format("HH:mm:ss");
    //           gotValue = true;
    //         } else if (operator == "-") {
    //           finalValue = mappedTime
    //             .subtract(numberCount[0]?.number, type)
    //             .format("HH:mm:ss");
    //           gotValue = true;
    //         }
    //       }

    //       if (
    //         timeCount &&
    //         timeCount.length > 1 &&
    //         numberCount &&
    //         numberCount.length > 0
    //       ) {
    //         let expr = "",
    //           type = "hours";

    //         if (this.data.time_result_type) {
    //           type = this.data.time_result_type.toLowerCase();
    //         }
    //         this.data.selected_fields.forEach((e) => {
    //           if (e?.type == "NUMBER") {
    //             expr = expr + e.number;
    //           } else if (e?.operator) {
    //             expr = expr + e.operator;
    //           } else if (e?.type == "PARENTHESES") {
    //             expr = expr + e.parentheses;
    //           } else if (e?.type == "FIELD" && e?.input_type == "TIME") {
    //             expr = expr + this.getTimeValue(data[e.key], type);
    //           }
    //         });
    //         try {
    //           let fValue = mexp.eval(expr);
    //           finalValue = this.convertHoursMinutesToSeconds(fValue, type);
    //           gotValue = true;
    //         } catch (er) {
    //           console.log("Error", er);
    //         }
    //       }
    //       // if all rea time and operators then we need to apply formula with values

    //       if (
    //         timeCount &&
    //         timeCount.length + operatorCount.length == array.length
    //       ) {
    //         let exp = array
    //           .map((field) => {
    //             if (field.time) {
    //               return moment.duration(field.time || "00:00:00").asSeconds();
    //             } else {
    //               return field.operator;
    //             }
    //           })
    //           .join("");

    //         let counts = mexp.eval(exp);
    //         gotValue = true;
    //         let type = "hours";
    //         if (this.data.time_result_type) {
    //           type = this.data.time_result_type.toLowerCase();
    //         }
    //         finalValue = this.convertSecondsToHoursMinutes(counts, type);
    //         }
    //       if (
    //         dateCount &&
    //         dateCount.length === 2 &&
    //         numberCount &&
    //         numberCount.length >= 1 &&
    //         operatorCount &&
    //         operatorCount.length >= 1
    //       ) {
    //         let dateArray = [];
    //         dateCount.map(async (date) => {
    //           dateArray.push(moment(date.date));
    //         });
    //         gotValue = true;

    //         if (this.data.date_result_type === "MM") {
    //           let counts = dateArray[0].diff(dateArray[1], "months");
    //           let cal = 0;
    //           if (numberCount.length && counts) {
    //             counts > 0 ? (cal = cal + counts) : (cal = cal + counts * -1);
    //             numberCount.map(async (number, index) => {
    //               cal = cal + operatorCount[index + 1].operator + number.number;
    //               finalValue = mexp.eval(cal) * 1;
    //             });
    //           }
    //         } else if (this.data.date_result_type === "YYYY") {
    //           let counts = dateArray[0].diff(dateArray[1], "years");
    //           let cal = 0;
    //           if (numberCount.length && counts) {
    //             counts > 0 ? (cal = cal + counts) : (cal = cal + counts * -1);
    //             numberCount.map(async (number, index) => {
    //               cal = cal + operatorCount[index + 1].operator + number.number;
    //               finalValue = mexp.eval(cal) * 1;
    //             });
    //           }
    //         } else {
    //           let counts = dateArray[0].diff(dateArray[1], "days");
    //           let cal = 0;
    //           if (numberCount.length) {
    //             counts > 0 ? (cal = cal + counts) : (cal = cal + counts * -1);
    //             numberCount.map(async (number, index) => {
    //               cal = cal + operatorCount[index + 1].operator + number.number;

    //               finalValue = mexp.eval(cal) * 1;
    //             });
    //           }
    //         }
    //       }

    //       if (
    //         dateCount &&
    //         dateCount.length === 2 &&
    //         operatorCount &&
    //         operatorCount.length === 1
    //       ) {
    //         let dateArray = [];
    //         dateCount.map(async (date) => {
    //           dateArray.push(moment(date.date));
    //         });

    //         gotValue = true;
    //         if (this.data.date_result_type === "MM") {
    //           let count = dateArray[0].diff(dateArray[1], "months");
    //           count > 0 ? (finalValue = count) : (finalValue = count * -1);
    //         } else if (this.data.date_result_type === "YYYY") {
    //           let count = dateArray[0].diff(dateArray[1], "years");
    //           count > 0 ? (finalValue = count) : (finalValue = count * -1);
    //         } else {
    //           let count = dateArray[0].diff(dateArray[1], "days");
    //           count > 0 ? (finalValue = count) : (finalValue = count * -1);
    //         }
    //       }

    //       if (
    //         dateCount &&
    //         dateCount.length === 1 &&
    //         numberCount &&
    //         numberCount.length === 1 &&
    //         operatorCount.length === 1 &&
    //         operatorCount[0].operator === "+"
    //       ) {
    //         let dateArray = [];
    //         dateCount.map(async (date) => {
    //           dateArray.push(moment(date.date));
    //         });
    //         gotValue = true;

    //         if (this.data.date_result_type === "MM") {
    //           var new_date = moment(dateArray[0], "DD-MM-YYYY").add(
    //             "months",
    //             numberCount[0].number
    //           );
    //         } else if (this.data.date_result_type === "YYYY") {
    //           new_date = moment(dateArray[0], "DD-MM-YYYY").add(
    //             "years",
    //             numberCount[0].number
    //           );
    //         } else {
    //           new_date = moment(dateArray[0], "DD-MM-YYYY").add(
    //             "days",
    //             numberCount[0].number
    //           );
    //         }
    //         var dates = moment(new_date).format("YYYY-MM-DD");
    //         finalValue = dates;
    //       }
    //       if (
    //         dateCount &&
    //         dateCount.length === 1 &&
    //         numberCount &&
    //         numberCount.length >= 1
    //       ) {
    //         let dateArray = [];
    //         dateCount.map(async (date) => {
    //           dateArray.push(moment(date.date));
    //         });
    //         gotValue = true;
    //         let expr = "";
    //         this.data.selected_fields.forEach((e, i) => {
    //           if (e.type == "FIELD") {
    //             if (e.input_type != "DATE") {
    //               expr += data[e.key] || 0;
    //             }
    //           } else if (
    //             e.type === "OPERATOR" &&
    //             this.data.selected_fields[i - 1] &&
    //             this.data.selected_fields[i - 1].input_type != "DATE"
    //           ) {
    //             expr += e.operator;
    //           } else if (e.type === "NUMBER") {
    //             expr += e.number;
    //           }
    //         });
    //         let v = mexp.eval(expr) * 1;
    //         let dataField,
    //           op = "add";
    //         this.data.selected_fields.forEach((e, i) => {
    //           if (e.type == "FIELD") {
    //             if (e.input_type == "DATE") {
    //               dataField = data[e.key];
    //             }
    //           } else if (
    //             e.type === "OPERATOR" &&
    //             this.data.selected_fields[i - 1] &&
    //             this.data.selected_fields[i - 1].input_type == "DATE"
    //           ) {
    //             op = e.operator == "-" ? "subtract" : "add";
    //           }
    //         });
    //         dataField = new Date(dataField);
    //         if (this.data.date_result_type === "MM") {
    //           new_date = moment(dataField, "DD-MM-YYYY")[op]("months", v);
    //         } else if (this.data.date_result_type === "YYYY") {
    //           new_date = moment(dataField, "DD-MM-YYYY")[op]("years", v);
    //         } else {
    //           new_date = moment(dataField, "DD-MM-YYYY")[op]("days", v);
    //         }
    //         dates = moment(new_date).format("YYYY-MM-DD");
    //         finalValue = dates;
    //       }

    //       if (
    //         dateCount &&
    //         dateCount.length === 1 &&
    //         numberCount &&
    //         numberCount.length === 1 &&
    //         operatorCount.length === 1 &&
    //         operatorCount[0].operator === "-"
    //       ) {
    //         let dateArray = [];
    //         dateCount.map(async (date) => {
    //           dateArray.push(moment(date.date));
    //         });
    //         gotValue = true;

    //         if (this.data.date_result_type === "MM") {
    //           var new_date_sub = moment(dateArray[0], "DD-MM-YYYY").subtract(
    //             "months",
    //             numberCount[0].number
    //           );
    //         } else if (this.data.date_result_type === "YYYY") {
    //           new_date_sub = moment(dateArray[0], "DD-MM-YYYY").subtract(
    //             "years",
    //             numberCount[0].number
    //           );
    //         } else {
    //           new_date_sub = moment(dateArray[0], "DD-MM-YYYY").subtract(
    //             "days",
    //             numberCount[0].number
    //           );
    //         }

    //         var date_sub = moment(new_date_sub).format("YYYY-MM-DD");
    //         finalValue = date_sub;
    //       }

    //       if (
    //         dateCount &&
    //         dateCount.length === 1 &&
    //         selectCount &&
    //         selectCount.length === 1 &&
    //         operatorCount[0].operator === "+"
    //       ) {
    //         let dateArray = [];
    //         dateCount.map(async (date) => {
    //           dateArray.push(moment(date.date));
    //         });
    //         gotValue = true;
    //         if (this.data.date_result_type === "MM") {
    //           var Globalnew_date = moment(dateArray[0], "DD-MM-YYYY").add(
    //             "months",
    //             selectCount[0].select
    //           );
    //         } else if (this.data.date_result_type === "YYYY") {
    //           Globalnew_date = moment(dateArray[0], "DD-MM-YYYY").add(
    //             "years",
    //             selectCount[0].select
    //           );
    //         } else {
    //           Globalnew_date = moment(dateArray[0], "DD-MM-YYYY").add(
    //             "days",
    //             selectCount[0].select
    //           );
    //         }
    //         var Globdates = moment(Globalnew_date).format("YYYY-MM-DD");
    //         finalValue = Globdates;
    //       }
    //       if (
    //         dateCount &&
    //         dateCount.length === 1 &&
    //         selectCount &&
    //         selectCount.length === 1 &&
    //         operatorCount[0].operator === "-"
    //       ) {
    //         let dateArray = [];
    //         dateCount.map(async (date) => {
    //           dateArray.push(moment(date.date));
    //         });
    //         gotValue = true;
    //         if (this.data.date_result_type === "MM") {
    //           var subGlobalnew_date = moment(
    //             dateArray[0],
    //             "DD-MM-YYYY"
    //           ).subtract("months", selectCount[0].select);
    //         } else if (this.data.date_result_type === "YYYY") {
    //           subGlobalnew_date = moment(dateArray[0], "DD-MM-YYYY").subtract(
    //             "years",
    //             selectCount[0].select
    //           );
    //         } else {
    //           subGlobalnew_date = moment(dateArray[0], "DD-MM-YYYY").subtract(
    //             "days",
    //             selectCount[0].select
    //           );
    //         }
    //         var subGlobdates = moment(subGlobalnew_date).format("YYYY-MM-DD");
    //         finalValue = subGlobdates;
    //       }
    //       if (
    //         dateCount &&
    //         dateCount.length === 1 &&
    //         listCount &&
    //         listCount.length === 1 &&
    //         operatorCount[0].operator === "+"
    //       ) {
    //         let dateArray = [];
    //         dateCount.map(async (date) => {
    //           dateArray.push(moment(date.date));
    //         });
    //         gotValue = true;
    //         if (this.data.date_result_type === "MM") {
    //           Globalnew_date = moment(dateArray[0], "DD-MM-YYYY").add(
    //             "months",
    //             listCount[0].list
    //           );
    //         } else if (this.data.date_result_type === "YYYY") {
    //           Globalnew_date = moment(dateArray[0], "DD-MM-YYYY").add(
    //             "years",
    //             listCount[0].list
    //           );
    //         } else {
    //           Globalnew_date = moment(dateArray[0], "DD-MM-YYYY").add(
    //             "days",
    //             listCount[0].list
    //           );
    //         }
    //         Globdates = moment(Globalnew_date).format("YYYY-MM-DD");
    //         finalValue = Globdates;
    //       }

    //       if (
    //         dateCount &&
    //         dateCount.length === 1 &&
    //         listCount &&
    //         listCount.length === 1 &&
    //         operatorCount[0].operator === "-"
    //       ) {
    //         let dateArray = [];
    //         dateCount.map(async (date) => {
    //           dateArray.push(moment(date.date));
    //         });
    //         gotValue = true;
    //         if (this.data.date_result_type === "MM") {
    //           subGlobalnew_date = moment(dateArray[0], "DD-MM-YYYY").subtract(
    //             "months",
    //             listCount[0].list
    //           );
    //         } else if (this.data.date_result_type === "YYYY") {
    //           subGlobalnew_date = moment(dateArray[0], "DD-MM-YYYY").subtract(
    //             "years",
    //             listCount[0].list
    //           );
    //         } else {
    //           subGlobalnew_date = moment(dateArray[0], "DD-MM-YYYY").subtract(
    //             "days",
    //             listCount[0].list
    //           );
    //         }

    //         subGlobdates = moment(subGlobalnew_date).format("YYYY-MM-DD");
    //         finalValue = subGlobdates;
    //       }

    //       if (numberCount && numberCount.length >= 2 && !dateCount.length) {
    //         exp = "";

    //         fields.forEach((element) => {
    //           if (fields[0].date) {
    //             exp += data[element.key] ? data[element.key] : 0;
    //           } else {
    //             if (element.key && element.key[0] == "#") {
    //               element.key = element.key.substring(1, element.key.length);
    //             }

    //             if (element.type == "OPERATOR") {
    //               exp += element.operator;
    //             } else if (element.type == "PARENTHESES") {
    //               exp += element.parentheses;
    //             } else if (element.type == "FORMULA") {
    //               if (element.template_id) {
    //                 exp += this.getTemplateValue(
    //                   element.template_id,

    //                   element.key
    //                 );
    //               } else {
    //                 let field = this.fieldsData.find(
    //                   (e) => e.key == element.key
    //                 );

    //                 if (!field && this.dataTableField?.data_table_columns) {
    //                   field = this.dataTableField.data_table_columns.find(
    //                     (e) => e.key == element.key
    //                   );
    //                 }

    //                 if (field?.time_result_type) {
    //                   exp += data[element.key]
    //                     ? this.convertSecondsToHoursMinutes(
    //                         data[element.key],

    //                         field.time_result_type.toLowerCase()
    //                       ) + ""
    //                     : 0;
    //                 } else {
    //                   exp += data[element.key] ? data[element.key] + "" : 0;
    //                 }
    //               }
    //             } else if (element.type == "NUMBER") {
    //               exp += element.number ? element.number : 0;
    //             } else if (element.type == "GLOBAL_VARIABLE") {
    //               exp += this.getGlobalVariableValue(element.field_name);
    //             } else {
    //               if (element.template_id) {
    //                 let value = this.getTemplateValue(
    //                   element.template_id,

    //                   element.key
    //                 );

    //                 exp += value ? value : 0;
    //               } else {
    //                 let pattern = /^[0-9a-fA-F]{24}$/;

    //                 let input = data[element.key];

    //                 if (pattern.test(input)) {
    //                   exp += data[element.key + "/name"]
    //                     ? data[element.key + "/name"]
    //                     : 0;
    //                 } else {
    //                   exp += data[element.key] ? data[element.key] : 0;
    //                 }
    //               }
    //             }

    //             gotValue = true;

    //             return;
    //           }
    //         });

    //         try {
    //           finalValue = mexp.eval(exp) * 1;

    //           gotValue = true;
    //         } catch (e) {
    //           console.log(e);
    //         }
    //       }
    //       if (
    //         numberCount &&
    //         numberCount.length >= 1 &&
    //         selectCount &&
    //         selectCount.length > 0
    //       ) {
    //         exp = "";
    //         fields.forEach((element) => {
    //           if (element.key && element.key[0] == "#") {
    //             element.key = element.key.substring(1, element.key.length);
    //           }
    //           if (element.type == "OPERATOR") {
    //             exp += element.operator;
    //           } else if (element.type == "PARENTHESES") {
    //             exp += element.parentheses;
    //           } else if (element.type == "FORMULA") {
    //             if (element.template_id) {
    //               exp += this.getTemplateValue(
    //                 element.template_id,
    //                 element.key
    //               );
    //             } else {
    //               exp += data[element.key] ? data[element.key] + "" : 0;
    //             }
    //           } else if (element.type == "NUMBER") {
    //             exp += element.number ? element.number : 0;
    //           } else if (element.type == "GLOBAL_VARIABLE") {
    //             exp += this.getGlobalVariableValue(element.field_name);
    //           } else {
    //             if (element.template_id) {
    //               let value = this.getTemplateValue(
    //                 element.template_id,
    //                 element.key
    //               );
    //               exp += value ? value : 0;
    //             } else {
    //               exp += data[element.key] ? data[element.key] : 0;
    //             }
    //           }

    //           gotValue = true;
    //           return;
    //         });
    //         try {
    //           finalValue = mexp.eval(exp) * 1;
    //           gotValue = true;
    //         } catch (e) {
    //           console.log(e);
    //         }
    //       }

    //       if (
    //         numberCount &&
    //         numberCount.length >= 1 &&
    //         listCount &&
    //         listCount.length > 0
    //       ) {
    //         exp = "";
    //         fields.forEach((element) => {
    //           if (element.key && element.key[0] == "#") {
    //             element.key = element.key.substring(1, element.key.length);
    //           }
    //           if (element.type == "OPERATOR") {
    //             exp += element.operator;
    //           } else if (element.type == "PARENTHESES") {
    //             exp += element.parentheses;
    //           } else if (element.type == "FORMULA") {
    //             if (element.template_id) {
    //               exp += this.getTemplateValue(
    //                 element.template_id,
    //                 element.key
    //               );
    //             } else {
    //               exp += data[element.key] ? data[element.key] + "" : 0;
    //             }
    //           } else if (element.type == "NUMBER") {
    //             exp += element.number ? element.number : 0;
    //           } else if (element.type == "GLOBAL_VARIABLE") {
    //             exp += this.getGlobalVariableValue(element.field_name);
    //           } else {
    //             if (element.template_id) {
    //               let value = this.getTemplateValue(
    //                 element.template_id,
    //                 element.key
    //               );
    //               exp += value ? value : 0;
    //             } else {
    //               exp += data[element.key] ? data[element.key] : 0;
    //             }
    //           }

    //           gotValue = true;
    //           return;
    //         });
    //         try {
    //           finalValue = mexp.eval(exp) * 1;
    //           gotValue = true;
    //         } catch (e) {
    //           console.log(e);
    //         }
    //       }
    //     }
    //   } else {
    //     exp = "";
    //     fields.forEach((element) => {
    //       if (element.key && element.key[0] == "#") {
    //         element.key = element.key.substring(1, element.key.length);
    //       }
    //       if (element.type == "OPERATOR") {
    //         exp += element.operator;
    //       } else if (element.type == "PARENTHESES") {
    //         exp += element.parentheses;
    //       } else if (element.type == "FORMULA") {
    //         if (element.template_id) {
    //           exp += this.getTemplateValue(element.template_id, element.key);
    //         } else {
    //           exp += data[element.key] ? data[element.key] + "" : 0;
    //         }
    //       } else if (element.type == "AGGREGATE_FUNCTION") {
    //         if (element.template_id) {
    //           exp += this.getTemplateValue(element.template_id, element.key);
    //         } else {
    //           exp += data[element.key] ? data[element.key] + "" : 0;
    //         }
    //       } else if (element.type == "NUMBER") {
    //         exp += element.number ? element.number : 0;
    //       } else if (element.type == "GLOBAL_VARIABLE") {
    //         exp += this.getGlobalVariableValue(element.field_name);
    //       } else {
    //         if (element.template_id) {
    //           let value = this.getTemplateValue(
    //             element.template_id,
    //             element.key
    //           );
    //           exp += value ? value : 0;
    //         } else {
    //           exp += data[element.key] ? data[element.key] : 0;
    //         }
    //       }

    //       gotValue = true;
    //       return;
    //     });
    //     try {
    //       finalValue = mexp.eval(exp) * 1;
    //       gotValue = true;
    //     } catch (e) {
    //       console.log(e);
    //     }
    //   }

    //   // if (this.data.key == "final_amount") {
    //   // }
    //   // if (this.data.key == "line_total") {
    //   // }
    //   if (gotValue) {
    //     if(this.data?.result_type == 'NUMBER' && isNaN(finalValue)){
    //       finalValue = 0;
    //     }
    //     if (isNaN(finalValue)) {
    //       if (
    //         /^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$/.test(finalValue)
    //       ) {
    //         //no statements required.  eliminating the is_date condition while using time difference in formula
    //       } else if (this.is_date(new Date(finalValue))) {
    //         finalValue = moment(finalValue).format(
    //           this.getDefaultDateFormat &&
    //             this.getDefaultDateFormat.includes("Do") == false
    //             ? this.getDefaultDateFormat
    //             : "MM-dd-yyyy"
    //         );
    //       }
    //     }
    //     if(Number(finalValue)){
    //       finalValue = parseFloat(finalValue).toFixed(this.data.decimals || 0)
    //     }
    //     this.$set(this.form, this.data.key, finalValue);
    //     // this.$emit("applyFormRules")
    //     return finalValue;
    //   } else {
    //     if(this.form[this.data.key] != 0 && this.form[this.data.key] != '0'){
    //       this.$set(this.form, this.data.key, 0);
    //     }
    //     // this.$emit("applyFormRules")
    //     return 0;

    //   }
    // },
  },
};
</script>

<style lang="scss" scoped>
.el-input {
  width: 100% !important;
  border: none !important;
  background: transparent !important;
}
.disabled-field {
  background-color: #f5f7fa !important;
  border-color: #e4e7ed;
  color: #c0c4cc;
  pointer-events: painted;
  cursor: not-allowed;
}

.el-input:focus {
  border: none !important;
  outline: none !important;
}

.custom-input {
  border: 1px;

  &:focus {
    outline: none;
    // border-color: #f7f3f3;
  }
}

.left-aligned-input {
  text-align: left;
}

.right-aligned-input {
  text-align: right;
}

.center-aligned-input {
  text-align: center;
}

.nfe {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 32px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 100%;
  &:hover {
    border-color: #409eff; /* Change the border color on hover */
  }
  &:focus {
    border-color: #409eff; /* Change the border color when focused */
  }
  &::placeholder {
    color: #ccc; /* Use your desired lighter gray color here */
  }
}
</style>
